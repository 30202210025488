import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preparacion',
  templateUrl: './preparacion.component.html'
})
export class PreparacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
