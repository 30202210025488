<!-- Nav -->
<app-nav-en></app-nav-en>


<!-- Banner 01 -->
<div class="col banner-mezcal text-center">
</div>

<div class="col  mb-5">
    <h1 class="my-5 text-center border-bottom pb-5">PRIVACY POLICY</h1>

    <!--  -->
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-12 my-5">
                

                <h3 class="mt-5">Dear Customer</h3>

                    <p>These General Terms of Use, Privacy Policy and Sales regulate the use of the website <a href="https://patascortas.co">patascortas.co</a> that Mazunte Projects ltd., makes available to people who access its Website in order to provide information and purchase regarding its products and services, those of their own and / or collaborating third parties, and to facilitate their access.</p>
                    
                    <p>Due to the fact that Mazunte Projects SAS de CV, is responsible for collecting and processing Personal Data that it has obtained or may obtain in the future, we have implemented mechanisms to guarantee the security of their information that the Federal Law of Protection of Personal Data in Possession of Individuals, categorized as "Personal Data"; for this reason, we make known our: PRIVACY NOTICE Based on the Law, its regulations and other applicable provisions, in this act we inform you that Mazunte Projects SAS de CV, with address in Dr. José Eleuterio González # 250 Int. 113-B, Colonia Santa María, CP64650, Monterrey, Nuevo León; whose website is <a href="https://patascortas.co">patascortas.co</a>, is responsible for collecting, the use and protection of Personal Data, in compliance with the principles protected by the Law.</p>
                    
                    <p>The confidentiality of Personal Data is guaranteed and they are protected by administrative, technical and / or physical security measures to avoid the damage, loss, alteration, destruction, use, access or undue disclosure of your Personal Data, which are found in an internal database, whose administration is through access codes that change periodically and whose access is restricted to authorized persons.</p>
                    
                    <p>Due to the very nature of the Website, as well as its content and purpose, the practical browsing that can be carried out must be done under the condition of Client, which is acquired according to the procedures set out in the website. Therefore, the aforementioned condition of Customer implies adherence to the General Conditions in the version published at the time that the Website is accessed. PATAS CORTAS Online Store reserves the right to modify, at any time, the presentation and configuration of the Website, as well as these General Conditions. For this reason, PATAS CORTAS Online Store recommends the Client read it carefully every time he accesses the Website. If you initiate any type of relationship with PATAS CORTAS, it will be understood that you have read, understood and agreed to the terms set out above.</p>
                    
                    <p>The purchase of PATAS CORTAS products requires the previous registration of the users, once they accept the General Conditions, becoming considered as Customers. The Client's identifier will be composed of his name, shipping address, email and bank details. Similarly, the customer accepts that there can be no cancellations or returns of product once approved and the sale is finalized.</p>
                    
                    <p>All contents of the Website, such as texts, graphics, photographs, logos, icons, images, as well as graphic design, source code and software, are the exclusive property of PATAS CORTAS Online Store or third parties, whose rights are legitimately held by PATAS CORTAS Online Store, being therefore protected by national and international legislation. It is strictly forbidden to use all the elements subject to industrial and intellectual property for commercial purposes, as well as its distribution, modification, alteration or decompilation.</p>
                    
                    <p>PATAS CORTAS reserves the right to modify this privacy notice at any time. Any modification to this Privacy Notice will be made known to you in this same Internet site: <a href="https://patascortas.co">patascortas.co</a>, without it being necessary to communicate said modification to you individually.</p>
                    
                    <p><b>Date of last update: 10/03/2018 | Personal data protection</b></p>


            </div>
        </div>
    </div>

</div>

<!-- Banner red -->
<div class="col banner-red-img text-center">
    <img class="img-fluid" src="./assets/img/for_the_plasure_of_sharing.png" alt="For the Plasure of Sharing">
</div>

<!-- Footer -->
<app-footer-en></app-footer-en>