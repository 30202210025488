import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mezcales',
  templateUrl: './mezcales.component.html'
})
export class MezcalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
