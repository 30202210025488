import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var hbspt: any;

@Component({
  selector: 'app-nav-en',
  templateUrl: './nav-en.component.html'
})
export class NavEnComponent implements OnInit {

  modalContact = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  clickContact () {
    this.modalContact= true;

    hbspt.forms.create({
      portalId: "20510126",
      formId: "3ba9533a-70e0-420e-a11e-1b81d937a1b2",
      target: "#test"
    });
  }

  clickClose() {
    this.modalContact= false;
  }

}
