
<router-outlet (activate)="onActivate($event)"></router-outlet>

<div class="wrap-modal" *ngIf="modalYear">
    <div class="modal-year text-center">
        
        <img src="../assets/img/Patas-Cortas.png" alt="Patas Cortas">
        <h3>Are you of legal drinking age in your country?</h3>
        <h3>¿Cumples con la edad legal para beber en tu pais?</h3>

        <div class="row my-5">
            <div class="col-md-4">
                <button (click)="clickYear()">YES</button>
            </div>
            <div class="col-md-4">
                <button>NO</button>
            </div>
            <div class="col-md-4">
                <button (click)="clickYear()">SI</button>
            </div>
        </div>
     
        <p>Al decir si, aceptas ser mayor de edad manifestando estar de acuerdo con los términos de confidencialidad y el uso de cookies de este sitio web. Para obtener más información lee nuestro Aviso de Privacidad.</p>
    </div>
</div>



