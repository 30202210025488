import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-es',
  templateUrl: './nav-es.component.html'
})
export class NavEsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
