import { Component } from '@angular/core';
// import {  Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'PatasCortas';

  modalYear = true;
  

  constructor() {}

  onActivate(event) {
    window.scroll(0,0);
  }

  clickYear(){
    this.modalYear = false;
  }


}
