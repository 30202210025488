<!-- Nav -->
<app-nav-en></app-nav-en>

<!-- Banner 01 -->
<div class="col banner-01">
    <a class="btn-wp" href="https://wa.me/+528116069546" target="_blank">WhatsApp Contact</a>
</div>

<!-- Banner 02 -->
<div class="container-fluid bg-pink">
    <div class="row">
        <div class="col py-5">
            <H1>FOR THE JOY OF A GOOD MEZCAL</H1>
            <div class="position-relative">
                <div class="gb-line position-absolute top-0 start-50 translate-middle"></div>
            </div>
            <div class="clearfix"></div>
            <div class="position-relative altura">
                <div class="pb-5">
                    <!-- Desk -->
                    <img class="img-fluid mb-5 pb-5 d-none d-md-block position-absolute top-50 start-50 translate-middle" src="./assets/img/mezcal_proceso_en.png" alt="Proces Patas Cortas">
                    <!-- Movil -->
                    <img class="img-fluid mt-5 mb-5 pb-5 d-block d-md-none position-absolute top-0 start-50 translate-middle-x" src="./assets/img/mezcal_proceso_movil_en.png" alt="Proces Patas Cortas">
                </div>
                <!-- Desk -->
                <img class="img-fluid d-none d-md-block position-absolute bottom-0 start-40 translate-middle-x" src="./assets/img/ancestral_technique.png" alt="Ancestral technique in the elaboration of Mezcal">
                <!-- Movil -->
                <img class="img-fluid d-block d-md-none position-absolute bottom-0 start-0" src="./assets/img/ancestral_technique_movil.png" alt="Ancestral technique in the elaboration of Mezcal">
            </div>
            <div class="row">
                <div class="col-sm-6 offset-sm-6 text-center mb-5">
                    <button class="green" (click)="clickPrepar()">Preparation</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Banner 03 -->
<div class="container-fluid bg-black">
    <div class="row">
        <div class="col-sm-6 col-xs-12 my-5">
            <img class="img-fluid" src="./assets/img/patascortas_mezcal.png" alt="Botellas patas cortas">
        </div>
        <div class="col-sm-6 col-xs-12 my-5">
            <img class="img-fluid" src="./assets/img/patascortas_mezcal_artesanal.png" alt="Patas Cortas">
            <div class="row">
                <div class="col-sm-12 text-center my-5">
                    <button class="red" (click)="onClickMe()">Mezcals</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Banner 04 -->
<div class="col bg-friends py-5 px-5">
    <img class="img-fluid" src="./assets/img/for_the_plasure_of_sharing.png" alt="For the Plasure of Sharing">
</div>

<!-- Contact -->
<div id="contact" class="container contacto">
    <div class="row my-5">
        <div class="col-lg-6 col-md-12 my-5">
            <div class="row">
                <div class="col mt-5">
                    <h3 class="mt-5">Contact</h3>
                    <h3 class="mail"><a href="mailto:hola@mazuntemx.com">hola@mazuntemx.com</a></h3>
                    <h3 class="link"><a href="http://patascortas.co/">patascortas.co</a></h3>
                    <p class="mt-3">
                        <a href="https://www.facebook.com/gallonortemezcal/" target="_blank">
                            <img class="social me-3" src="./assets/img/fb-pink.png" alt="Facebook">
                        </a>
                        <a href="https://www.instagram.com/patascortasmezcal/" target="_blank">
                            <img class="social" src="./assets/img/in-pink.png" alt="Instagram">
                        </a>
                    </p>
                    <p class="mt-5">Patas Cortas is a brand of</p>
                    <img class="img-fluid" src="./assets/img/mazunte_logo.png" alt="Mazunte">
                </div>
                <div class="col mt-5">
                    <img class="img-fluid mt-5" src="./assets/img/contacto3.png" alt="Mezcal con limon">
                </div>
            </div>
        </div>
        <div id="hubspotForm" class="col-lg-6 col-md-12 my-5">

        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-en></app-footer-en>