<!-- Nav -->
<app-nav-en></app-nav-en>

<!-- Banner 01 -->
<div class="col banner-preparacion text-center">
</div>

<div class="col  mb-5">
    <h1 class="my-5 text-center border-bottom pb-5">FOR THE JOY OF A GOOD MEZCAL</h1>

    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center my-5 col-img">
                <img class="img-fluid" src="./assets/img/ancestral_technique_2.png" alt="Técnica ancestral en la elaboración del Mezcal">
            </div>
            <div class="col-md-6 my-5">
                
                <p>Patas Cortas Mezcal is prepared with the expert hands of our mezcal master José Luis Hernández who with a technique that passed from generation to generation gives Patas Cortas mezcal a distinct and unique flavor.</p>

                <p>The Mezcal is 100% agave, which is carefully selected since each pine cone must meet the necessary requirements to go through our process.</p>
                    
                <p>The agave must be at least 10 years old and be at a certain height and have the ideal humidity to ensure the contributions of sugars that achieve the exact alcohol levels to give it the distinctive flavor we are looking for. Once the pine cones are harvested and made into jimas they are taken to the ovens for cooking, which is done slowly, patiently, since we leave the pine cones at least 72 hours and up to 6 days.</p>
                    
                <p>Later, with an ancient technique, by means of a circular mill of quarry stone pulled by a horse, the milling is done with extreme care.</p>
        
                <p>In order to achieve this unique flavor, the fermentation process is done in pine wood vats, this way we achieve the desired flavor of our Mezcal.</p>
    
                <p>Finally, a double distillation is carried out to ensure the desired alcohol levels and once the process is finished, it is packaged and labeled by hand so that the next step is to enjoy it just for the pleasure of enjoying a good Mezcal.</p>
            </div>
        </div>
    </div>

</div>

<!-- Banner pink -->
<div class="col banner-pink-img text-center">
    <img class="img-fluid" src="./assets/img/for_the_plasure_of_sharing.png" alt="For the Plasure of Sharing">
</div>

<!-- Footer -->
<app-footer-en></app-footer-en>