

<!-- Nav -->
<app-nav-en></app-nav-en>

<!-- Banner 01 -->
<div class="col banner-mezcal text-center">
</div>

<div class="col  mb-5">
    <h1 class="my-5 text-center border-bottom pb-5">MEZCALS</h1>

    <!-- Espadin -->
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-6 text-center px-5 col-img">
                <img class="img-fluid" src="./assets/img/Botella-Espadin-SF.png" alt="Mezcal Espadin">
            </div>
            <div class="col-md-6 my-5">
                <div class="mezcal">
                    <h3>Mezcal</h3>
                    <h1>ESPADIN</h1>
                    <div class="line"></div>
                </div>

                <p class="mt-5">Made with the best Espadín (A. Angustifolia) maguey pine cones from Santiago Matatlán, it is a double distillation Mezcal, with balanced herbal aromas, with slightly soft tones, astringent and smoked on the palate, 100% handmade.</p>

                <p>Alcoholic richness 45% Alc. Vol.</p>

                <p class="mb-5"><b>Type / Category:</b> White / Young
                    <br><b>Mezcal Master:</b> José Luis Hernández
                    <br><b>State and Zone:</b> Oaxaca, Santiago Matatlán
                    <br><b>Local name of the agave:</b> Espadín
                    <br><b>Type to which it belongs:</b> A. Angustifolia
                    <br><b>Oven Type:</b> Stone conical kiln
                    <br><b>Type of Grinding:</b> Quarry Mill
                    <br><b>Type of fermentation na:</b> Pine wood
                    <br><b>Type of distiller:</b> Copper alembic
                    <br><b>Certifications:</b> Certificado por el Consejo Regulador del Mezcal
                    <br><b>% of Alc. Vol:</b> 45°
                    <br><b>Net content:</b> 750 ml.</p>


            </div>
        </div>
    </div>

    <hr class="my-5">

    <!-- Tobala -->
    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center px-5 col-img">
                <img class="img-fluid" src="./assets/img/Botella-Tobala-SF.png" alt="Mezcal Tobala">
            </div>
            <div class="col-md-6 my-5">
                <div class="mezcal">
                    <h3>Mezcal</h3>
                    <h1>TOBALÁ</h1>
                    <div class="line"></div>
                </div>

                <p class="mt-5">100 % wild Agave (A. Potatorum), a very aromatic mezcal, intense smells of wood, with smoky touches and notes of grass and earth, a strong flavor and delicate agave notes, a perfect balance between the smell and flavor, 100% handmade.</p>

                <p>Alcoholic richness 45% Alc. Vol.</p>

                <p class="mb-5"><b>Type / Category:</b> White / Young
                    <br><b>Mezcal Master:</b> José Luis Hernández
                    <br><b>State and Zone:</b> Oaxaca, Santiago Matatlán
                    <br><b>Local name of the agave:</b> Tobalá
                    <br><b>Type to which it belongs:</b> A. Potatorum
                    <br><b>Oven Type:</b> Stone conical kiln
                    <br><b>Type of Grinding:</b> Quarry Mill
                    <br><b>Type of fermentation na:</b> Pine wood
                    <br><b>Type of distiller:</b> Copper alembic
                    <br><b>Certifications:</b> Certified by the Mezcal Regulatory Board
                    <br><b>% of Alc. Vol:</b> 45°
                    <br><b>Net content:</b> 750 ml.</p>


            </div>
        </div>
    </div>

    <hr class="my-5 mt-5">

    <!-- Madrecuishe -->
    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center px-5 col-img">
                <img class="img-fluid" src="./assets/img/Botella-Madrecuishe-SF.png" alt="Mezcal Madrecuishe">
            </div>
            <div class="col-md-6 my-5">
                <div class="mezcal">
                    <h3>Mezcal</h3>
                    <h1>MADRECUISHE</h1>
                    <div class="line"></div>
                </div>

                <p class="mt-5">100% wild Agave Karwinsnkii pine cones, give birth to this unique, strong-bodied mezcal which is a harmonious blend of earthy, herbal and moist scents. The fruity and bitter balanced flavors stand out on the palate, with abundant “pearls” and with a degree of alcohol that makes the flavor linger in a subtle way on the palate.</p>

                <p>Alcoholic richness 45% Alc. Vol.</p>

                <p class="mb-5"><b>Type / Category:</b> White / Young
                    <br><b>Mezcal Master:</b> José Luis Hernández
                    <br><b>State and Zone:</b> Oaxaca, Santiago Matatlán
                    <br><b>Local name of the agave:</b> Madrecuishe
                    <br><b>Type to which it belongs:</b> A. Karwinskii
                    <br><b>Oven Type:</b> Stone conical kiln
                    <br><b>Type of Grinding:</b> Quarry Mill
                    <br><b>Type of fermentation na:</b> Pine wood
                    <br><b>Type of distiller:</b> Copper alembic
                    <br><b>Certifications:</b> Certified by the Mezcal Regulatory Board
                    <br><b>% of Alc. Vol:</b> 45°
                    <br><b>Net content:</b> 750 ml.</p>


            </div>
        </div>
    </div>


</div>


<!-- Banner pink -->
<div class="col banner-cyan-img text-center">
    <img class="img-fluid" src="./assets/img/for_the_plasure_of_sharing.png" alt="For the Plasure of Sharing">
</div>

<!-- Footer -->
<app-footer-en></app-footer-en>