<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">

        <a class="navbar-brand d-flex align-items-center text-dark text-decoration-none" routerLink="/">
            <img class="logo img-fluid" src="./assets/img/logo_patascortas.png" alt="Patas Cortas">
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
                <!-- <li class="nav-item"><a (click)="clickAbout" class="nav-link link-dark">About us</a></li>
                <li class="nav-item"><a (click)="clickMezcal" class="nav-link link-dark">Mezcales</a></li>
                <li class="nav-item"><a (click)="clickPreparation" class="nav-link link-dark">Preparation</a></li>
                <li class="nav-item"><a (click)="clickContact" class="nav-link link-dark active" aria-current="page">Contact</a></li> -->

                <li class="nav-item"><a class="nav-link link-dark" routerLink="/about_us" routerLinkActive="active">About us</a></li>
                <li class="nav-item"><a class="nav-link link-dark" routerLink="/mezcals" routerLinkActive="active">Mezcales</a></li>
                <li class="nav-item"><a class="nav-link link-dark" routerLink="/preparation" routerLinkActive="active">Preparation</a></li>
                <li class="nav-item"><a class="nav-link link-dark" (click)="clickContact()">Contact</a></li>

                <li class="nav-item">
                    <a href="https://www.facebook.com/gallonortemezcal/" target="_blank" class="px-1 nav-link"><img src="./assets/img/fb-bn.png" alt="Facebook Gallo Norte"></a>
                </li>
                <li class="nav-item">
                    <a href="https://www.instagram.com/patascortasmezcal/" target="_blank" class="px-1 me-3 nav-link"><img src="./assets/img/in-bn.png" alt="Instagram Patas Cortas"></a>
                </li>
                <!-- <li class="nav-item">
                    <a href="#" class="px-1 nav-link"><img src="./assets/img/flag_mexico_nation_icon.png" alt="Spanish"></a>
                </li>
                <li class="nav-item">
                    <a href="#" class="px-1 nav-link"><img src="./assets/img/flag_nation_states_icon.png" alt="English"></a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>


<div class="wrap-contact" *ngIf="modalContact">
    <div class="modal-contact">
        <div class="close" (click)="clickClose()">X</div>
        <h3>Contact</h3>
        <div id="test" >      

        </div>
    </div>
</div>
