import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preparation',
  templateUrl: './preparation.component.html'
})
export class PreparationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
