import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpClientModule } from '@angular/common/http';

// import { AppRoutingModule } from './app-routing.module';

// Rutas
import { APP_ROUTING } from './app-routing.module';

import { AppComponent } from './app.component';

import { NavEsComponent } from './nav/nav-es/nav-es.component';
import { NavEnComponent } from './nav/nav-en/nav-en.component';
import { FooterEnComponent } from './footer/footer-en/footer-en.component';
import { FooterEsComponent } from './footer/footer-es/footer-es.component';

import { IndexComponent } from './home/index/index.component';
import { AboutComponent } from './about/about/about.component';
import { MezcalsComponent } from './mezcals/mezcals/mezcals.component';
import { MezcalesComponent } from './mezcals/mezcales/mezcales.component';
import { PreparationComponent } from './preparation/preparation/preparation.component';
import { PreparacionComponent } from './preparation/preparacion/preparacion.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './legal/terms-conditions/terms-conditions.component';


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    FooterEnComponent,
    FooterEsComponent,
    NavEsComponent,
    NavEnComponent,
    AboutComponent,
    MezcalsComponent,
    MezcalesComponent,
    PreparationComponent,
    PreparacionComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    // AppRoutingModule,
    // HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
