<!-- Nav -->
<app-nav-en></app-nav-en>

<!-- Banner 01 -->
<div class="col banner-mezcal text-center">
</div>

<div class="col  mb-5">
    <h1 class="my-5 text-center border-bottom pb-5">TERMS AND CONDITIONS</h1>

    <div class="container mb-5">
        <div class="row">
            <div class="col-md-12 mb-5">

                <p class="mt-5">These General Conditions of Use, Conditions of Sale and Privacy Policy regulate the use of the website <a href="https://patascortas.co">patascortas.co</a> (hereinafter "the Website"), of which Mazunte Projects (from now on referred as THE COMPANY)</p>

                <p>Through its website <a href="https://patascortas.co">patascortas.co</a>, THE COMPANY provides information about its products and offers the possibility of its acquisition. Due to the content and purpose of the Website, people who want to benefit from their services must have the status of "Customer", which is acquired by completing the registration form and following the steps that THE COMPANY subsequently communicates via email. The condition of Customer implies adherence to the Terms of Use, the version published at the time the Website is accessed.</p>
                    
                <p>In any case, there are pages of the Website accessible to individuals or legal entities that fail to register or initiate a product purchase (hereinafter, referred as "Users"). In this sense, Users who access these parts of the Website agree to be subject to the terms and conditions set forth in these General Conditions, to the extent that this may be applicable.</p>
                    
                <p>THE COMPANY wants to let its Clients and Users know that it is addressed exclusively to a public over 18 years of age and that the territory in which it accepts and distributes orders is that comprised in Mexico and the United States (Hereinafter, referred as the "Territory"). THE COMPANY does not ship orders abroad or to cities other than those mentioned above. If a user is interested in receiving an item outside the mentioned territory, he should contact THE COMPANY through the corresponding form or telephone. For any kind of doubt, question or suggestion, you can send us your comments by email to: <a href="mailto:hola@mazuntemx.com">hola@mazuntemx.com</a></p>
                    
                <h3>Product</h3>
                
                <p>The descriptions of the products displayed on the Website are based on the information provided by Mazunte Projects SAS de CV. However, the information given about each product, as well as the photographs or videos related to them and the trade names, trademarks or distinctive signs of any kind contained in the website of THE COMPANY, are displayed in <a href="https://patascortas.co">patascortas.co</a> as a guide.</p>
                    
                <h3>Prices</h3>
                    
                <p>All the prices of the products that are indicated through the website, are in Mexican pesos and include VAT and any other taxes that may correspond. The prices do NOT include the expenses corresponding to the shipment of the products. This information may be modified by the COMPANY without prior notice.</p>
                
                <p>The prices of <a href="https://patascortas.co">patascortas.co</a> are unique, exclusive of this means and can change without previous notice, they do not apply for the different channels of the company.</p>
                    
                <h3>Availability</h3>
                    
                <p>THE COMPANY informs the Client that the number of available units is kept updated with the stocks in the store. Under no circumstances THE COMPANY will intentionally sell more units than it has available.</p>
                    
                <p>THE COMPANY will do everything possible to please all its Customers in the demand for the products. However, at times, and due to causes that are difficult for the COMPANY to control such as human errors or incidents in the computer systems, it is possible that the quantity finally served by the supplier differs from the order made by THE COMPANY to satisfy the orders of the Clients.</p>

                <p>In the event that the product is not available after the order has been placed, the Customer will be informed by email of the total or partial cancellation of the order. The partial cancellation of the order due to lack of availability does not give the right to cancel the entire order.</p>
                    
                <h3>Payment</h3>
                    
                <p>The Customer agrees to pay at the time the order is placed. The ticket or proof of purchase corresponding to the purchase order will be available and can be viewed at <a href="https://patascortas.co">patascortas.co</a> in the section "My account", "Orders".</p>
                    
                <p>The Customer must pay the amount corresponding to his order by payment through credit card (Visa, MasterCard, American Express) or debit from Mexican banks identified or identified at the time of payment. Payment by card is made through the OPENPAY platform with its security protocols.</p>
                    
                <p>The Client must notify THE COMPANY of any undue charge on the card used for purchases, by email or by telephone, in the shortest possible time so that THE COMPANY can make the necessary arrangements.</p>
                    
                <h3>SECURITY</h3>
                    
                <p>THE COMPANY has the highest security measures commercially available in the sector. In addition, the payment process works on a secure server using the SSL (Secure Sockets Layer) protocol. The secure server establishes a connection so that the information is transmitted encrypted, which ensures that it is only intelligible for the Client's computer and that of the Website. In this way, using the SSL protocol guarantees:</p>
                    
                <p>That the Client is communicating his data to the server center of THE COMPANY and not to any other that tries to pass itself as this one.</p>
                    
                <p>The data between the Client and the server center of THE COMPANY is transmitted encrypted, avoiding its possible reading or manipulation by third parties.</p>
                    
                <h3>FORMALIZATION OF ORDERS</h3>
                    
                <p>Once the order has been formalized, that is, with the acceptance of the Conditions of Use and the confirmation of the purchase process, THE COMPANY will always send an email to the CLIENT confirming the details of the purchase made.</p>
                    
                <h3>DEADLINES, PLACE OF DELIVERY AND EXTRAVIOS</h3>
                    
                <h4>Product delivery</h4>
                    
                <p>THE COMPANY is committed to deliver the product in perfect condition at the address indicated by the Customer in the order form, which in any case must be included within the Territory. In order to optimize the delivery, we show our gratitude to the Client for indicating an address in which the order can be delivered during normal business hours.</p>
                    
                <p>THE COMPANY will not be responsible for the errors caused in the delivery when the delivery address entered by the Customer in the order form does not conform to reality or has been omitted.</p>
                    
                <p>THE COMPANY informs the Customer that it is possible that the same order is divided into several deliveries.</p>
                    
                <h4>Delivery time</h4>
                    
                <p>Shipments will be made through a courier company. The order placed by you will be delivered within a maximum period of 2 weeks after we have made the order confirmation.</p>
                
                <p>These deadlines are means, and therefore an estimate. Therefore, they may vary due to logistical reasons or force majeure. In cases of delays in deliveries, THE COMPANY will inform its customers as soon as it becomes aware of them.</p>
                    
                <p>Each delivery is considered carried out from the moment in which the shipping company puts the product at the disposal of the Customer, which is materialized through the control system used by the shipping company.</p>
                    
                <p>Cases in which the order has been made available to the Customer by the shipping company within the agreed time and could not be delivered for reasons attributable to the Customer will not be considered as delays in the delivery.</p>
                    
                <p><b>III. Delivery Data, Undelivered Shipments and Cases of Loss.</b></p>
                    
                <p>If at the time of delivery the Customer is absent, the carrier will leave a receipt indicating how to proceed to arrange a new delivery. THE COMPANY contracts, as part of the courier delivery service, the performance of a series of follow-up actions, aimed at guaranteeing that the delivery will take place.</p>
                    
                <h4>Diligence in the delivery.</h4>
                    
                <p>The Customer must check the good condition of the package in the presence of the carrier that, on behalf of THE COMPANY, makes the delivery of the requested product, any anomaly that could be detected on the packaging.</p>
                    
                <p>If, subsequently, once the product has been reviewed, the Customer detects any damage such as blow, breakage, signs of having been opened or any damage caused to it by the shipment, the latter undertakes to notify THE COMPANY by sending an email to <a href="mailto:hola@mazuntemx.com">hola@mazuntemx.com</a> in the shortest possible time, attaching photographs showing the damage, as well as a brief description.</p>
                    
                <p>In case the damage is our responsibility, the change of merchandise will be made in a maximum no more than 45 days; it is informed that there are no refunds to the CLIENT.</p>
                    
                <h3>RETURN POLICY</h3>
                    
                <p>The products purchased at THE COMPANY DO NOT have a guarantee; so there are no returns.</p>
                    
                <h3>GUARANTEE OF THE ACQUIRED PRODUCTS</h3>
                
                <p>THE COMPANY guarantees that the products presented for sale on the site <a href="https://patascortas.co">patascortas.co</a> are of high quality. In addition, they are in perfect condition and have no defects, or hidden defects that could make them dangerous or inadequate.</p>
                
                <h3>INTELLECTUAL AND INDUSTRIAL PROPERTY</h3>
                
                <p>THE COMPANY holds all rights to the content, design and source code of this website and, especially, with an enunciative but not limitative to the photographs, images, texts, logos, designs, trademarks, trade names and data that are include in the Web.</p>
                
                <p>The content of this Site is the property of THE COMPANY, a company duly constituted and existing in accordance with the laws of the United Mexican States. The Site is protected by the Federal Law of Copyright, by the Law of Industrial Property and other relative laws and international treaties in the matter and its management and administration is the responsibility of THE COMPANY.</p>
                
                <p>The information contained in this Site complies with current Mexican legislation.</p>
                
                <p>It is expressly forbidden the total or partial reproduction of this website, or any of its contents.</p>
                
                <p>THE COMPANY informs that it does not grant any license or implicit authorization on the rights of intellectual and / or industrial property or on any other right or property related, directly or indirectly, with the contents included in the Web.</p>
                
                <p>Only the use of the contents of the web domain for information and service purposes is authorized, provided that the source is cited or referenced, and the user is solely responsible for the misuse of the same. Likewise, the user may not include in any other site all or any part of the content of the Site and may not use any of the images, graphics, trademarks, texts, and others contained in the materials of the Site. THE COMPANY does not grant any license and / or right to any user of this Site on its patents, trademarks, copyrights or any exclusive property rights of THE COMPANY or its affiliates.</p>
                
                <h3>ACCESS AND STAY ON THE WEB. OUR CONTENTS.</h3>
                
                <p>The Clients and Users are fully responsible for their conduct, when accessing the information on the Web, while they are browsing it, as well as after they have accessed it.</p>
                
                <p>THE COMPANY reserves the right to update the contents when it deems appropriate, as well as to eliminate, limit or prevent access to them, temporarily or permanently, as well as denying access to the Website to Clients and Users who misuse the contents and / or breach any of the conditions that appear in this document.</p>
                
                <h3>OUR RESPONSIBILITY</h3>
                
                <p>THE COMPANY does not assume any responsibility derived, with an enunciative but not limitative to:</p>
                
                <p>The use that Clients or Users may make of the materials of this Website or link websites, whether prohibited or permitted, in violation of the intellectual and / or industrial property rights of the contents of the Website or third parties.</p>
                
                <p>Of the possible damages and losses to the Clients or Users caused by a normal or abnormal functioning of the search tools, of the organization or location of the contents and / or access to the Web and, in general, of the errors or problems that are generated in the development or instrumentation of the technical elements that the Web or a program facilitates to the User.</p>
                    
                <p>The access of minors to the contents included in the Web, being the responsibility of their parents or guardians to exercise an adequate control over the activity of the minor children in their charge or to install any of the control tools of the Internet use with the object to prevent them from making purchases and sending personal data without the prior authorization of their parents or guardians.</p>
                    
                <p>THE COMPANY will not be responsible in any case of:</p>
                
                <p>Errors or delays in accessing the Website by the Customer at the time of entering their information on the order form, the slowness or inability to receive the order confirmation or any anomaly that may arise when these incidents are due to problems on the Internet, causes of unforeseeable circumstances or force majeure and any other unpredictable contingency other than the good faith of THE COMPANY.</p>
                
                <p>In any case, THE COMPANY is committed to solve any problems that may arise and to offer all the necessary support to the Client to reach a quick and satisfactory solution.</p>

                <p>The use of this Site may be monitored and analyzed by MAZUNTE PROJECTS LTD. and any information derived from said analysis may be used for statistical evaluations and / or to improve the Site, in accordance with the Site's Comprehensive Privacy Notice.</p>
                
                <h3>CHANGES WITHIN THE TERMS OF USE.</h3>
                
                <p>THE COMPANY reserves the right to modify, at any time, the presentation and configuration of the Website, as well as these General Conditions. Therefore, THE COMPANY recommends the Client to read them carefully every time he accesses the Website.</p>
                
                <p>Clients and Users will always have these Terms of Use in a visible, freely accessible site. In any case, acceptance of the Terms of Use will be a preliminary and essential step to the acquisition of any product available through the Website.</p>
                
                <p>These terms and conditions shall have full effect from the date of publication of the Site and shall remain in force until THE COMPANY so determines.</p>
                
                <p>In case any of these terms and conditions is declared null, the other terms and conditions will remain in force and have full effect.</p>
                
                <p>Any right not expressly granted in these terms and conditions is reserved for THE COMPANY.</p>
                
                <p>In case of any dispute arising from the use of this site, the user expressly submits to the applicable laws and competent courts of the City of Monterrey Nuevo León, and expressly waives any other jurisdiction that by reason of present or future address may correspond.</p>
                
                <p><strong>IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, PLEASE DO NOT USE THIS SITE.</strong></p>


            </div>
        </div>
    </div>

</div>

<!-- Banner red -->
<div class="col banner-red-img text-center">
    <img class="img-fluid" src="./assets/img/for_the_plasure_of_sharing.png" alt="For the Plasure of Sharing">
</div>

<!-- Footer -->
<app-footer-en></app-footer-en>