import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var hbspt: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})

export class IndexComponent implements OnInit {

  

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    hbspt.forms.create({
      portalId: "20510126",
      formId: "3ba9533a-70e0-420e-a11e-1b81d937a1b2",
      target: "#hubspotForm"
    });
  }

  onClickMe () {
    this.router.navigate(['./mezcals']);
  }
  clickPrepar() {
    this.router.navigate(['./preparation']);
  }

}
