import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { HomeComponent } from './components/home/home.component';
import { IndexComponent } from './home/index/index.component';
import { AboutComponent } from './about/about/about.component';
import { MezcalsComponent } from './mezcals/mezcals/mezcals.component';
import { PreparationComponent } from './preparation/preparation/preparation.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './legal/terms-conditions/terms-conditions.component';


const APP_ROUTES: Routes = [
  { path: 'home', component: IndexComponent },
  { path: 'about_us', component: AboutComponent },
  { path: 'mezcals', component: MezcalsComponent },
  { path: 'preparation', component: PreparationComponent },
  { path: 'privacy_policy', component: PrivacyPolicyComponent },
  { path: 'terms&conditions', component: TermsConditionsComponent },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
