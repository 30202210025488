<!-- Nav -->
<app-nav-en></app-nav-en>

<!-- Banner 01 -->
<div class="col banner-conoce text-center">
</div>

<div class="col  mb-5">
    <h1 class="my-5 text-center border-bottom pb-5">RECOGNIZE PATAS CORTAS</h1>

    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center col-img">
                <img class="img-fluid" src="./assets/img/banner_conoce_en.jpg" alt="Técnica ancestral en la elaboración del Mezcal">
            </div>
            <div class="col-md-6 my-5">
                <p>Patas Cortas was born from the idea of creating the perfect Mezcal, we dreamed of the perfect flavor and we wanted it to be seen in each bottle.</p>

                <p>Respecting the traditions and typical flavorsof Mexico is extremely important for us. Patas Cortas is a high quality and sophisticated mezcal for people who enjoy the taste of a truly authentic mezcal.

                    <p>From the land of Santiago Matatlan, Oaxaca. “Patas Cortas” is made from 100% agave. When you taste our mezcal you are taken into a gastronomic experience which can be shared with good friends allowing you to create new stories.</p>

                    <p>Our philosophy as a company is to provide a different and intense flavor that allows those who taste it to experience the aromas and textures on their palate.</p>

                    <p>By manufacturing it in small batches with the expert hands of our Mescalero Master we take care of every small detail in order to provide a handmade mezcal of high quality in a production of small lots.</p>

                    <p>We are passionate about mezcal and everything around it. That is why we seek to provide the best flavor, this passion has taken us to explore the various mezcal locations in Mexico where we found agaves with more than 10 years
                        of gestation.</p>

                    <p>Patas Cortas mezcal is endorsed by the regulatory council of mezcal num NOM 0619X.</p>

                    <h5 class="mb-0"><span>MISSION.</span></h5>
                    <p>Do things with passion every day and listen to our customers.</p>

                    <h5 class="mb-0"><span>VISION.</span></h5>
                    <p>Let other countries get to know the taste of our Mezcal.</p>

                    <h5 class="mb-0"><span>VALUES.</span></h5>
                    <p>Inclusion, Transparency, Commitment, Humility, Gratitude and Sensibility.</p>


            </div>
        </div>
    </div>

</div>

<div class="col  mb-5">
    <h1 class="my-5 text-center border-bottom pb-5">Unique flavor, exclusive for Mezcal connoisseur.</h1>

    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center col-img">
                <img class="img-fluid" src="./assets/img/banner_04_mini.jpg" alt="Técnica ancestral en la elaboración del Mezcal">
            </div>
            <div class="col-md-6 my-5">
                <p>In Patas Cortas we seek to create a bond of empathy that generates welfare and growth in the locality and its communities. </p>

                <p>Consuming a bottle of Patas Cortas contributes to the sustainability of the region.</p>
            </div>
        </div>
    </div>

</div>

<!-- Banner red -->
<div class="col banner-red-img text-center">
    <img class="img-fluid" src="./assets/img/for_the_plasure_of_sharing.png" alt="For the Plasure of Sharing">
</div>

<!-- Footer -->
<app-footer-en></app-footer-en>